<script setup>
import { ref, defineExpose, defineEmits, watch } from 'vue';

import AnexarArquivos from '../AnexarArquivos.vue';
import PerguntaMultiplaEscolha from '../grid-elaboracao/PerguntaMultiplaEscolha.vue';
import PerguntaCombo from '../grid-elaboracao/PerguntaCombo.vue';
import PerguntaDissertativa from '../grid-elaboracao/PerguntaDissertativa.vue';
import PerguntaNumeroInteiro from '../grid-elaboracao/PerguntaNumeroInteiro.vue';
import FormRegistrarPendencia from '../FormRegistrarPendencia.vue';
import { useAlert } from '../../../../composables/useAlert.js';
import { getClientSesmt } from '../../../../services/http-sesmt';
import SesmtService from '../../../../services/SesmtService';
import { useRouter } from 'vue-router';
import TipoAnexoEnum from '../../../../enums/TipoAnexoEnum';
import OrigemAnexoEnum from '../../../../enums/OrigemAnexoEnum';
import { helpers, requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useFocusElement } from '../../../../utils/useFocusElement.js';

const { currentRoute } = useRouter();
const { alertError, alertSucess, alertErrorData } = useAlert();
const service = new SesmtService(
    `aplicacao-pgr-elaboracao/aplicacao-questionario/elaboracao/${currentRoute.value.params.idElaboracao}/update-by-origem`
);
const isOpen = ref(false);
const loading = ref(false);
const emit = defineEmits(['update:visible']);
const loadingSalvar = ref(false);
const componentsMap = {
    CB: PerguntaCombo,
    ME: PerguntaMultiplaEscolha,
    DI: PerguntaDissertativa,
    IN: PerguntaNumeroInteiro
};
const form = ref([]);
const origemId = ref();
const pendencias = ref();
const pendencia = ref();
const temPendencia = ref();

watch(
    () => pendencia.value?.pendencias,
    () => {
        temPendencia.value = pendencia.value?.pendencias.length;
    }
);

const load = async (id) => {
    try {
        loading.value = false;
        const { data } = await getClientSesmt().get(`aplicacao-pgr-elaboracao/aplicacao-respostas/origem-id/${id}/origem/AMBIENTE`);

        form.value = data?.aplicacaoResposta;
        origemId.value = data?.aplicacaoResposta?.[0]?.origemId;
        pendencias.value = data?.aplicacaoPgrElaboracaoPendencia;
    } catch (error) {
        const { message } = error?.response?.data;
        alertError(`Erro ao listar! ${message}`);
    } finally {
        loading.value = false;
    }
};

const fieldsRef = ref([]);
const rules = {
    form: {
        $each: helpers.forEach({
            resposta: {
                required: helpers.withMessage(
                    'Resposta obrigatória',
                    requiredIf((_, itemForm) => itemForm.perguntaObrigatoria)
                )
            }
        })
    }
};

const v$ = useVuelidate(rules, { form });

const salvar = async () => {
    try {
        const isValid = await v$.value.form.$validate();

        if (!isValid) {
            focarCampoObrigatorio();
            return;
        }

        loadingSalvar.value = true;
        await service.save({
            id: form.value[0].origemId,
            origemId: form.value[0].origemId,
            origem: form.value[0].origem,
            inventarioRiscoId: '1904',
            perguntasRespostas: form.value.map((item) => ({
                id: item.id,
                resposta: item.resposta,
                descricao: item.perguntaSesmt.descricao,
                mensagemAjuda: item.perguntaSesmt.mensagemAjuda,
                tipo: item.perguntaSesmt.tipo,
                opcoes: item.perguntaSesmt.opcoes
            })),
            questionarioSesmtId: form.value[0].questionarioSesmtId,
            etapa: form.value[0].etapa,
            inserirAnexo: form.value[0].questionarioSesmt.inserirAnexo,
            anexoObrigatorio: form.value[0].questionarioSesmt.inserirAnexo,
            respostasConcatenadas: form.value.map((item) => `${item.perguntaSesmt.descricao}: ${item.resposta}`).join(' - ')
        });

        alertSucess('Questionário salvo com sucesso.');
        closeDialog();
    } catch (error) {
        if (error?.response?.data?.details?.response?.retornoErrosCadastro?.contemErrosCadastro) {
            closeDialog();
            return;
        }

        alertErrorData(error, 'Não foi possível salvar o questionário, por favor tente novamente.');
    } finally {
        loadingSalvar.value = false;
    }
};

const encontrarPrimeiroCampoComErro = () => {
    return v$.value.form.$model.findIndex((_, index) => v$.value.form.$each.$response.$errors[index].resposta?.length);
};

const focusElement = useFocusElement();
const focarCampoObrigatorio = () => {
    const firstErrorFieldIndex = encontrarPrimeiroCampoComErro();

    if (firstErrorFieldIndex !== -1 && fieldsRef.value[firstErrorFieldIndex]) {
        const identificacao = `campo-${firstErrorFieldIndex}`;
        const element = document.getElementById(identificacao);

        if (element) {
            focusElement.byId(identificacao);

            return;
        }

        focusElement.byClassName(identificacao);
    }
};

const openDialog = async (id) => {
    await load(id);
    isOpen.value = true;
};

const closeDialog = () => {
    isOpen.value = false;
    v$.value.$reset();
    emit('update:visible');
};

defineExpose({
    openDialog
});
</script>
<template>
    <Dialog
        v-model:visible="isOpen"
        :style="{ width: '688px', height: 'auto' }"
        header="Questionário de ambiente"
        @hide="closeDialog"
        :draggable="false"
        modal
    >
        <div class="flex flex-column gap-2 w-full line-height-3">
            <div class="grid flex flex-column align-items-start">
                <div v-for="(item, index) in form" :key="item.id" class="col-6 mb-3 w-full">
                    <component
                        :is="componentsMap[item.perguntaSesmt.tipo]"
                        :tipo="item.perguntaSesmt.tipo"
                        :descricao="item.perguntaSesmt.descricao"
                        :opcoes="item.perguntaSesmt.opcoes"
                        :ordem="item.ordem"
                        :obrigatoria="item.perguntaObrigatoria"
                        v-model="v$.form.$model[index].resposta"
                        :isInvalid="Boolean(v$.form.$each.$response.$errors[index].resposta?.length && v$.form.$dirty)"
                        :elementId="`campo-${index}`"
                        :ref="(el) => (fieldsRef[index] = el)"
                    />

                    <small v-if="v$.form.$each.$response.$errors[index].resposta?.length && v$.form.$dirty" class="p-error">
                        {{ v$.form.$each.$response.$errors[index].resposta?.[0].$message }}
                    </small>
                </div>
            </div>
        </div>
        <div class="w-full mt-6 mb-6">
            <AnexarArquivos :origemId="origemId" :origem="OrigemAnexoEnum.CHECKLIST_AMBIENTE" :tipo="TipoAnexoEnum.CHECKLIST_AMBIENTE" />
        </div>
        <Message v-if="temPendencia" class="mb-5" severity="info">Só é possível validar se todas as pendências estiverem concluídas.</Message>
        <FormRegistrarPendencia :origemId="origemId" origem="AMBIENTE" ref="pendencia" />
        <template #footer>
            <div class="flex aling-itens-end pt-4">
                <Button label="Cancelar" class="p-button-text justify-content-end" @click="closeDialog()" />
                <Button
                    :label="temPendencia ? 'Salvar' : 'Salvar e validar'"
                    class="p-button-primary justify-content-end"
                    @click="salvar"
                    :loading="loadingSalvar"
                />
            </div>
        </template>
    </Dialog>
</template>
<style scoped lang="scss">
.grupo-botoes-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    .button-link {
        margin-right: 20px;
    }
}
</style>
